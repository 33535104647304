.main--vid-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 3.6rem;
  display: grid;
  grid-template-rows: 1fr;
  gap: 0rem;
  justify-items: center;
  background-color: #000;
}

.main--vid-wrapper::before {
  content: "";
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-image: url("../imgs/background.jpeg");
  background-size: cover;
  z-index: 0;
}

.logo--container {
  padding: 2.8rem 2.8rem 2.8rem 1.8rem;
  color: #c38547;
  letter-spacing: 0.25rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: fit-content;
  position: fixed;
  top: 2.5%;
  left: 2.5%;
  z-index: 1;

  animation: fade-in 0.8s forwards;
  -webkit-animation: fade-in 0.8s forwards;
}

.original {
  font-size: 4rem;
  font-family: "Playwrite HU", cursive;
  font-style: normal;
}

.original:last-child {
  padding-left: 1.2rem;
}

.section--nav {
  position: relative;
  z-index: 3;
  height: fit-content;
}

.menu--btn {
  color: #fff;
  position: absolute;
  right: 5%;
  top: 5%;
  cursor: pointer;
  font-size: 2.4rem;
}

.nav--list {
  color: #c38547;
  display: flex;
  list-style: none;
  font-size: 2.1rem;
  gap: 10rem;
}

.nav--list li {
  cursor: pointer;
  transition: all 0.3s;
  border-bottom: 0.1rem solid transparent;
}

.nav--list li:hover {
  border-bottom: 0.1rem solid #c38547;
}

.cur--nav {
  border-bottom: 0.1rem solid #c38547 !important;
}

.main--vid-btn {
  border: none;
  height: fit-content;
  transition: all 0.3s;
  text-align: center;
}

.spotify--album--container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow-y: scroll !important;
  gap: 2.4rem;
  margin-top: 0rem;
}

.contact-wrapper {
  min-height: fit-content;
}

.performance--wrapper {
  width: 80% !important;
  height: 80% !important;
}

.performance--form {
  position: relative;
  background: url("../imgs/performance.jpeg") no-repeat;
  background-position: center;
  box-sizing: border-box;
  box-shadow: inset -650px 0 150px 0 #fff;
  padding-left: 65rem;
  padding-top: 10rem;
  text-align: center;
  letter-spacing: 0.02rem;
  font-size: 2.1rem;
  line-height: 1.4;
}

.performance--text {
  position: relative;
  /* height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; */
}

.performance--img img {
  display: none;
}

.book--btn {
  background-color: #eddac8;
  width: fit-content;
  padding: 1.2rem 3.2rem;
  cursor: pointer;
  border-radius: 0.4rem;
  transition: all 0.3s;
  position: absolute;
  left: 50%;
  bottom: -100%;
  transform: translateX(-50%);
}

.book--btn:hover {
  background-color: #c38547;
}

.contact--form {
  min-height: fit-content !important;
  display: flex;
  align-items: center;
}

.david--form {
  width: 80% !important;
  height: 80% !important;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 5%;
  padding: 2.1rem 1.2rem;
  padding-right: 5%;
  letter-spacing: 0.02rem;
}

.david--text h2 {
  padding: 2.1rem 2.4rem;
  color: #475367;
  font-size: 1.6rem;
  font-weight: 900;
  letter-spacing: 0.1rem;
}

.david--text p {
  font-size: 1.8rem;
  padding: 1rem 2.4rem;
  line-height: 1.6;
}

.spotify--album {
  width: 100%;
  height: 100%;
  height: fit-content;
}

.science-of-music:link,
.science-of-music:visited {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.4rem;
  transition: all 0.3s;
  text-decoration: none;
  padding-right: 2.4rem;
}

.science-of-music:hover,
.science-of-music:active {
  transform: scale(1.02);
}

.science-of-music img {
  height: 5.2rem;
  width: 5.2rem;
}

.science-of-music p {
  color: #a5a5a5;
  font-family: "Noto Serif Georgian", serif;
  white-space: nowrap;
}

.social--media-links {
  display: flex;
  align-items: center;
  gap: 2.4rem;
  background-color: #f6f0eb;
  padding: 2.8rem;
}

.links {
  display: flex;
  gap: 1.8rem;
}

.listen--btn-container {
  display: flex;
  gap: 2.4rem;
  cursor: default;
  padding: 0.8rem 3.2rem;
}

.main--vid-btn-icon {
  border-radius: 50%;
  background: none;
}

.main--vid-btn-icon:hover {
  animation: wiggle 2s linear;
  -webkit-animation: wiggle 2s linear;
}

.main--vid-btn-icon a {
  text-decoration: none;
}

.contact--form,
.book--form {
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  gap: 4.8rem;
  height: fit-content !important;
}

.contact--form div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

label {
  font-size: 1.6rem;
  width: fit-content;
}

input {
  font-size: 1.6rem;
  padding: 0.8rem 1.6rem;
  border-radius: 0.4rem;
  width: 98%;
}

select {
  font-size: 1.8rem;
}

textarea {
  font-size: 1.6rem;
  padding: 1rem 2rem;
  border-radius: 0.4rem;
  min-height: 10rem;
}

.btn--section {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-items: end;
  width: 80%;
}

.book--form {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 2.4rem;
  position: absolute;
  padding: 3.6rem;
  box-shadow: 0.4rem 0.4rem 1.8rem rgba(0, 0, 0, 0.04);
  top: 0%;
  right: 0%;
  margin-top: 0rem;
  width: 100% !important;
  height: 100% !important;
  background-color: #eddac8;
  box-shadow: 0.4rem 0.4rem 1.8rem rgba(0, 0, 0, 0.04);
  z-index: 3;
}

.book--form p {
  font-size: 1.6rem;
  width: 55%;
  text-align: center;
  margin: 0 auto;
}

.book--form textarea {
  min-height: 10rem;
}

.performance--text p {
  padding: 1rem;
}

.submit--booking--form-btn {
  width: 100%;
  box-shadow: 0.1rem 0.1rem 0.4rem rgb(0, 0, 0);
}

.links {
  position: absolute;
  right: 1%;
  top: 20%;
  flex-direction: column;
}

.btn--icon {
  color: #4e4235;
  padding: 1.4rem 3.6rem;
  border-radius: 1.2rem;
  letter-spacing: 0.05rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;

  animation: fade-in 2s forwards;
  -webkit-animation: fade-in 2s forwards;
}

.section--footer {
  position: relative;
  font-size: 1.4rem;
  color: #888;
  padding: 0rem 3.6rem 1.8rem 3.6rem;
}

.fade-in {
  animation: fade-in 0.5s forwards;
  -webkit-animation: fade-in 0.5s forwards;
}

.slide-in {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

.immediate-close {
  animation: slide-out 0s forwards;
  -webkit-animation: slide-out 0s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes wiggle {
  0%,
  7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%,
  100% {
    transform: rotateZ(0);
  }
}

@-webkit-keyframes wiggle {
  0%,
  7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%,
  100% {
    transform: rotateZ(0);
  }
}
